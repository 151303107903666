




























import {FirstPage} from "@/request/shop/firstPage";
import el_add_advertise from '@/components/first-page-components/el-add-advertise1.vue'

export default {
    name: "Advertise1",
    props: {
        id: {
            type: String
        }
    },
    data() {
        return {
            list: []
        }
    },
    components: {
        'el-add-advertise': el_add_advertise
    },
    created() {
        let self: any = this;
        self.getData();
    },
    methods: {
        getData() {
            let self: any = this;
            FirstPage.getAdvertiseList(self.id).then((body: any) => {
                if (body.code === 0) {
                    self.list = body.data;
                }
            })
        },
        add() {
            let self: any = this;
            self.$refs['add-advertise'].show();
        },
        remove(id: string) {
            let self: any = this;
            FirstPage.deleteAdvertise(id).then((body: any) => {
                if (body.code === 0) {
                    self.getData();
                    self.$parent.$emit('on-remove')
                }
            })
        },
        onSubmit(advertise: any) {
            let self: any = this;
            FirstPage.saveAdvertise({pageId: self.id, advertiseId: advertise.id}).then((body: any) => {
                if (body.code === 0) {
                    self.getData();
                    self.$parent.$emit('on-add')
                }
            })
        },
        onPopperShow() {
            let self: any = this;
            self.$forceUpdate();
        }
    }

}
